/*!
 * SpreadNShare 3.0 (http://www.virtualys.com)
 * Copyright 2008-2015 Virtualys / IFREMER
 */
.c_menu.l_core_breadcrumb .c_body {
  background: none;
  color: #004f9e;
  font-size: .9em;
}
.c_menu.l_core_breadcrumb span {
  padding: 0 8px;
}
.c_menu.l_core_breadcrumb .control {
  height: 25px;
  line-height: 25px;
  width: 25px;
  background: #01bbf4;
  color: #fff;
}
.c_menu.l_core_breadcrumb .control span {
  padding: 0;
  width: auto;
}
.c_menu.l_core_breadcrumb .control:hover {
  background: #fff;
  color: #004f9e;
}
.c_menu.l_core_breadcrumb .frame > ul > li,
.c_menu.l_core_breadcrumb .children,
.c_menu.l_core_breadcrumb .location {
  line-height: 25px;
}
.c_menu.l_core_breadcrumb .segment:first-child .children span {
  padding: 0 10px 0 0;
}
.c_menu.l_core_breadcrumb .segment::after {
  content: "\f054";
  font: normal normal normal 0.85em/1 FontAwesome;
}
.c_menu.l_core_breadcrumb .segment .location::before,
.c_menu.l_core_breadcrumb .segment .children::after {
  display: none;
}
#slysr_dropdowns ul.dropdown-menu {
  max-width: 400px;
}
#slysr_dropdowns ul.dropdown-menu > li > a {
  overflow: hidden;
  text-overflow: ellipsis;
}
/*# sourceMappingURL=cpnt_snshtml5_menu.breadcrumb-theme.css.map */